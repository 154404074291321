/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/style.scss'
import { Helmet } from "react-helmet"
const React = require("react")

export const wrapRootElement = ({ element }) => {
    return (
      <>
        {element}
        <Helmet>
            {/* {typeof window !== "undefined" && (
                <script async>
                    {(function(s, u, m, o, j, v) {
                    j = u.createElement(m);
                    v = u.getElementsByTagName(m)[0];
                    j.async = 1;
                    j.src = o;
                    j.dataset.sumoSiteId =
                    "61bf159c0d6eac4a9ca71be4da244c564b4edfd2d6aaaed5a819b2310d30966a";
                    v.parentNode.insertBefore(j, v);
                })(window, document, "script", "//load.sumo.com/")}
                </script>
        )} */}
        {typeof window !== "undefined" && (
            <link defer={true} rel="dns-prefetch" href="https://fonts.gstatic.com" />
        )}
        {typeof window !== "undefined" && (
            <link defer={true} href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap" rel="stylesheet" />
        )}
        {typeof window !== "undefined" && (
            <link defer={true} href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600&display=swap" rel="stylesheet" />
        )}
        {typeof window !== "undefined" && (
                <script defer={true} rel="dns-prefetch" data-uid="15458ada2b" src="/convertkit.js"></script>
        )}
        </Helmet>
      </>
    )
  }