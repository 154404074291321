// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-rails-course-js": () => import("./../../../src/pages/rails-course.js" /* webpackChunkName: "component---src-pages-rails-course-js" */),
  "component---src-templates-advanced-python-js": () => import("./../../../src/templates/advanced-python.js" /* webpackChunkName: "component---src-templates-advanced-python-js" */),
  "component---src-templates-javascript-js": () => import("./../../../src/templates/javascript.js" /* webpackChunkName: "component---src-templates-javascript-js" */),
  "component---src-templates-other-js": () => import("./../../../src/templates/other.js" /* webpackChunkName: "component---src-templates-other-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-python-course-js": () => import("./../../../src/templates/python-course.js" /* webpackChunkName: "component---src-templates-python-course-js" */),
  "component---src-templates-python-machine-learning-js": () => import("./../../../src/templates/python-machine-learning.js" /* webpackChunkName: "component---src-templates-python-machine-learning-js" */),
  "component---src-templates-sql-js": () => import("./../../../src/templates/sql.js" /* webpackChunkName: "component---src-templates-sql-js" */)
}

